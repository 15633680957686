import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { EVENT_NAMES } from 'src/services/analytics-service';
import { useComponentContext } from 'src/components/layout';
import { ArrowRight } from 'src/components/common/Icons';
import { link_, linkWithArrow_, linkWithIcon_, arrow_, isDarkTheme_ } from './CTALink.module.scss';

const getLinkThemeClass = (themePropOverride, darkThemeContext) => {
  if (themePropOverride === 'dark') {
    return isDarkTheme_;
  }

  // Need to possibly override the section theme if button is on a light background
  if (darkThemeContext && themePropOverride !== 'light') {
    return isDarkTheme_;
  }

  return '';
};

const CTALink = ({ onClick, children, className, href, withArrow = false, icon, theme = 'none', ...rest }) => {
  const { shouldUseDarkTheme } = useComponentContext();
  const themeClassName = getLinkThemeClass(theme, shouldUseDarkTheme);

  return (
    <SmartLink
      className={classNames(
        link_,
        withArrow && linkWithArrow_,
        icon && linkWithIcon_,
        className,
        themeClassName
      )}
      href={href}
      onClick={onClick}
      eventData={{ name: EVENT_NAMES.TERTIARY_CTA_CLICKED, label: children }}
      {...rest}
    >
      <span>
        {icon}
        {children}
        {' '}
        {withArrow && <ArrowRight size="sm" className={arrow_} />}
      </span>
    </SmartLink>
  );
};

// CTALink props
CTALink.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  withArrow: PropTypes.bool,
  icon: PropTypes.node,
  theme: PropTypes.oneOf(['none', 'dark', 'light']),
};

export { CTALink };
