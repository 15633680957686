import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { ArrowRight } from 'src/components/common/Icons';
import { link_, linkWithArrow_, linkWithIcon_, arrow_ } from './CTALink.module.scss';

const CTALinkStyle = ({ children, className, withArrow = false, icon, ...rest }) => {
  return (
    <span
      className={classNames(link_, withArrow && linkWithArrow_, icon && linkWithIcon_, className)}
      {...rest}
    >
      <span>
        {icon}
        {children}
        {' '}
        {withArrow && <ArrowRight size="sm" className={arrow_} />}
      </span>
    </span>
  );
};

// CTALink props
CTALinkStyle.propTypes = {
  className: PropTypes.string,
  withArrow: PropTypes.bool,
  icon: PropTypes.node,
};

export { CTALinkStyle };
