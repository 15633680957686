export const translations = {
  'en-ca': {
    'errors::404::title': 'The page you’re looking for does not exist (404) | Wealthsimple',
    'errors::404::heading': 'We couldn’t find what you are looking for.',
    'errors::404::subheading': 'Here are some helpful links instead:',
    'errors::404::links::home': 'Home',
    'errors::404::links::support': 'Support',
    'errors::500::title': 'There was a problem with this page (500) | Wealthsimple',
    'errors::500::heading': 'An error has occurred on this page.',
    'errors::500::subheading': 'Please try again in a few minutes.',
  },
  'fr-ca': {
    'errors::404::title': 'Cette page n’existe pas (404) | Wealthsimple',
    'errors::404::heading': 'Nous n’avons pas pu trouver l’information recherchée.',
    'errors::404::subheading': 'Cependant, voici quelques liens qui pourraient vous être utiles :',
    'errors::404::links::home': 'Accueil',
    'errors::404::links::support': 'Centre d’aide',
    'errors::500::title': 'Une erreur est survenue (500) | Wealthsimple',
    'errors::500::heading': 'Une erreur est survenue.',
    'errors::500::subheading': 'Nous nous penchons sur le problème. Veuillez réessayer plus tard.',
  },
};
