'use client';

import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { HELP_SITE_URL_EN, HELP_SITE_URL_FR } from 'src/utils/urls';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { FeatureBlock, FeatureBlockCopy, FeatureBlockMedia } from 'src/components/subsections/FeatureBlock';
import { Image } from 'src/components/common/Image';
import { Footer } from '../Footer';
import { PageContent } from '../PageContent';
import { PageLayout } from '../PageLayout';
import { Section } from '../Section';
import genericErrorImage from './media/generic.png';
import { translations } from './ErrorPageLayout.translations';

const ContentFor404 = () => {
  const { isFr } = useTranslationContext();

  return (
    <div className="flex flex-col">
      <SmartLink href="/">{useTranslation(`errors::404::links::home`)}</SmartLink>
      <SmartLink href={isFr ? HELP_SITE_URL_FR : HELP_SITE_URL_EN}>
        {useTranslation(`errors::404::links::support`)}
      </SmartLink>
    </div>
  );
};

export const ErrorPageLayout = ({ errorCode }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <PageLayout customFooter={<Footer showTLDRFooter={false} />}>
      <PageContent>
        <Section>
          <FeatureBlock>
            <FeatureBlockCopy>
              <h1 className="ws-display-sm-sans mb-sm">
                {useTranslation(`errors::${errorCode}::heading`)}
              </h1>
              <p className="mb-sm">{useTranslation(`errors::${errorCode}::subheading`)}</p>
              {errorCode.toString() === '404' && <ContentFor404 />}
            </FeatureBlockCopy>
            <FeatureBlockMedia>
              <Image src={genericErrorImage} alt="" width="702" height="338" />
            </FeatureBlockMedia>
          </FeatureBlock>
        </Section>
      </PageContent>
    </PageLayout>
  );
};
